import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import EmailIcon from "../icons/mail.svg";
import LockIcon from "../icons/lock.svg";
import useCheckUser from "../hooks/useCheckUser";
import { toast } from "react-toastify";
import "../styles/auth.css";
import Hero from "../components/home/Hero"

const AuthForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [session, setSession] = useState(null);
  const [error, setError] = useState(null);
  const [isCooldown, setIsCooldown] = useState(false);
  const [msg, setMsg] = useState(null); 
  const [ReferrerId, setReferrerId] = useState(null);
  const [username, setUsername] = useState("");

  useCheckUser(setSession);

  useEffect(() => {
    if (session !== null) {
      navigate("/profile");
    }
  }, [session, navigate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referrerIdParam = urlParams.get("ref");
    if (referrerIdParam) {
      setReferrerId(referrerIdParam);
    }
    
  }, [])


  const HandleForgotPassword = async () => {
    if (isCooldown) return; // Prevent multiple clicks during cooldown
    if (!email) {
      setError("Please enter your email to recover password");
      return;
    }

    setIsCooldown(true); // Start cooldown
    setError("");
    setMsg("Please allow up to 5 minutes to receive the recovery email."); // Set the message

    let { data, error } = await supabase.auth.resetPasswordForEmail(email);

    if (error) {
      console.error("Error resetting password:", error.message);
    } else {
      console.log("DATA: " + data);

      toast("Recovery sent to email", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    // Set a cooldown of 5 minutes (300000 milliseconds)
    setError("");
    setTimeout(() => setIsCooldown(false), 300000);
  };



  const handleAuth = async (isLogin) => {
    try {
      
      if (!validateEmail(email)) {
        setError("Please enter a valid email address.");
        return;
      }
      // Check if password is at least 6 characters long
      if (password.length < 6) {
        setError("Please enter a password with 6 or more characters.");
        return;
      }

      if (isLogin) {
        const { data, error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });

        if (data?.user !== null) {
          console.log("Login success");
          navigate("/profile");
        } else if (error) {
          console.error("Login error:", error.message);
          setError(error.message);
        }
      } else {


        const { data, error } = await supabase.auth.signUp({
          email,
          password,
        });
  
        console.log(data);

        if (data?.user !== null) {
          // Referral logic immediately after sign-up
          if (ReferrerId) {
            try {

              const referralData = {
                reffered_by: ReferrerId,
                reffered_to: data.user.id,
              };
              const { data: referralResult, error: referralError } = await supabase
                .from("referrals")
                .upsert([referralData], { onConflict: ["reffered_by", "reffered_to"] });
  
              if (referralResult) {
                console.log("Referral added to the table:", referralResult);
                console.log("Login + Refferal success");
                navigate("/profile");

              } else if (referralError) {
                console.error("Error adding referral:", referralError.message);
              }
            } catch (referralError) {
              console.error("Error adding referral:", referralError.message);
            }
          }
          await supabase.auth.refreshSession();
          
          console.log("Signed up");
          navigate("/profile");
          // toast("Please confirm your email!", {
          //   position: "top-center",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "dark",
          // });
  
          // Ensure referral tracking completes before redirecting
        } else if (error) {
          // Handle the case where the email is already in use
          if (
            error.code === "23505" &&
            error.message.includes('unique constraint "auth_users_email_key"')
          ) {
            setError(
              "Email is already in use."
            );
          } else {
            setError('Please enter details to signup');
          }
        }
      }
    } catch (error) {
      console.error("Authentication error:", error.message);
      setError(error.message);
    }
  };

  useEffect(() => {
    const checkUsername = async () => {
      try {
        const { data, error } = await supabase
          .from('username')
          .select('*')
          .eq('user_id', ReferrerId);
  
        if (error) {
          console.error('Error checking username:', error);
          return;
        }
  
        const fetchedUsername = data.length > 0 ? data[0].username : null;
        setUsername(fetchedUsername);
      } catch (error) {
        console.error('Error checking username:', error);
      }
    };
  
    if (ReferrerId) {
      checkUsername();
    }
  }); 

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (

<body className="wrapper">
<video className="video" autoPlay muted playsInline loop>
    <source src="/spVid.mp4" type="video/mp4" />
</video>

<div className="container">



<div className="logoContainer">
    <h1 className="headerLogo">sp</h1>
</div>

<div className="headerContainer">
    <h2 className="headerh1"><strong>Track</strong> your progress,</h2>
    <h2 className="headerh1"><strong>Show</strong> it off</h2>
</div>

{error && <p className="auth-error">{error}</p>}
{msg && <p className="auth-msg">{msg}</p>}
{ReferrerId && username === null ? (
    <h2 className="auth-h2">Loading...</h2>
) : ReferrerId && username !== null ? (
    <h2 className="auth-h2-ref">Referred by: {username}</h2>
) : null}

<form onSubmit={(e) => e.preventDefault()} className="auth-form">
    <label className="auth-form-label">
        <img className="auth-form-icon" src={EmailIcon} alt="Email" />
        <input
            className="auth-form-input"
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
        />
    </label>
    <label className="auth-form-label">
        <img className="auth-form-icon" src={LockIcon} alt="Password" />
        <input
            className="auth-form-input"
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
        />
             <div 
              className={`forgot-btn ${isCooldown ? 'disabled' : ''}`} 
              onClick={HandleForgotPassword}
            >
              {isCooldown ? 'Sent' : 'Forgot?'}
            </div>
    </label>

    <div className="ButtonContainer">
        <button
            className="buttonSignup"
            type="button"
            onClick={() => handleAuth(false)}
        >
            Signup
        </button>
        <button
            type="button"
            className="buttonLogin"
            onClick={() => handleAuth(true)}
        >
            Login
        </button>
        
    </div>

  
  </form>
</div>

</body>
   
  );
};

export default AuthForm;
