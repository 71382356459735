import React, { useState, useEffect, lazy, Suspense } from "react";
import { useParams, useNavigate,  } from "react-router-dom";
import supabase from "../config/supabaseClient";
import PumpCard from "../components/PumpCard";
import Masonry from "react-masonry-css";
import Username from "../components/CreateUsername";
import "../styles/profile.css";

import useCheckViews from '../hooks/useCheckViews';

import '../index.css';

const Profile = () => {
  const navigate = useNavigate();
  const [fetchError, setFetchError] = useState(null);
  const [user, setUser] = useState(null);
  const [userPumps, setUserPumps] = useState(null);
  const [referralCount, setReferralCount] = useState(0);
  const { userId } = useParams();
  const [userStreak, setUserStreak] = useState('')
  const [userPosition, setUserPosition] = useState(null);
  const [streakEmoji, setStreakEmoji] = useState("");

  const [usernameCheckSuccess, setUsernameCheckSuccess] = useState(true);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const { viewsCount, incrementViews } = useCheckViews(user?.id || '');
  const [greeting, setGreeting] = useState("");

  const [numColumns, setNumColumns] = useState(2);


  const handleDoublePress = () => {
    console.log('')
  }

  const getGreetingMessage = () => {
    const currentHour = new Date().getHours();
    const randomChance = Math.random();
  
    if (randomChance < 0.3) { // 70% chance for one of the first three greetings
      const currentHour = new Date().getHours();
      if (currentHour >= 3 && currentHour < 12) {
        setGreeting("Good morning");
      } else if (currentHour >= 12 && currentHour < 17) {
        setGreeting("Good afternoon");
      } else {
        setGreeting("Good evening");
      }
    } else { // 30% chance for one of the eight additional greetings
      const otherGreetings = [
        "Looking freaky",
        "How u doin",
        "What’s up",
        "Lookin good",
        "Hiiiiiiii",
        "Heyyyyyyyy",
        "Welcome back",
        "Howdy",
        "Wassup",
        "Hiya"
      ];
      const randomIndex = Math.floor(Math.random() * otherGreetings.length);
      setGreeting(otherGreetings[randomIndex]);
    }
  };
  
  const checkUsername = async () => {
    try {
      const { data, error } = await supabase
        .from("username")
        .select("*")
        .eq("user_id", user.id);

      if (error) {
        console.error("Error checking username:", error);
        return;
      }

      const fetchedUsername = data.length > 0 ? data[0].username : null;
      setUsername(fetchedUsername);
    } catch (error) {
      console.error("Error checking username:", error);
    }
  };

  const handleVisibility = async (pumpId) => {
    
    try {
      const pumpToUpdate = userPumps.find((pmp) => pmp.id === pumpId);

      if (!pumpToUpdate) {
        console.error("Pump not found:", pumpId);
        return;
      }

      const updatedStatus = !pumpToUpdate.visible;

      const { data, error } = await supabase
        .from("pumps")
        .update({ visible: updatedStatus })
        .eq("id", pumpId);

      if (error) {
        console.error("Error updating visible boolean:", error);
        return;
      }

      console.log("Update result:", data);

      fetchUserPumps();
    } catch (error) {
      console.error("Error updating visible boolean:", error);
    }
  };

  const handleDelete = async (pumpId, filePath) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to Delete this?"
    );

    if (!confirmDelete) {
      return;
    }

    try {
      const filePaths = Array.isArray(filePath)
        ? filePath
        : filePath.split(",");

      const trimmedFilePaths = filePaths.map((path) => path.trim());

      const removeImagePromises = trimmedFilePaths.map(async (path) => {
        try {
          await supabase.storage.from("pumpgallery").remove([path]);
          console.log("Image deleted:", path);


          const { data: existingStreakData, error: existingStreakError } = await supabase
          .from('streaks')
          .select('streak')
          .eq('user_id', user.id);
    
        if (existingStreakData && existingStreakData.length > 0) {
          // User has already updated their streak for today, subtract 1 from the streak
          const currentStreak = existingStreakData[0].streak;
          const newStreak = Math.max(0, currentStreak - 1); // Ensure streak doesn't go negative
    
          // Update streak count in the 'streaks' table
          const { data: streakData, error: streakError } = await supabase
            .from('streaks')
            .update({ streak: newStreak })
            .eq('user_id', user.id);
  
          if (streakError) {
            console.error('Error updating streak:', streakError);
            // Handle error updating streak...
          } else {
            console.log('Streak updated:', newStreak);
          }
        }


        } catch (deleteImageError) {
          console.error("Error deleting image:", deleteImageError);
          throw deleteImageError;
        }
      });

      await Promise.all(removeImagePromises);

      const { data: deletePumpData, error: deletePumpError } = await supabase
        .from("pumps")
        .delete()
        .eq("id", pumpId);

      if (deletePumpError) {
        console.error("Error deleting pump:", deletePumpError);
        return;
      }

      window.location.reload()
    } catch (error) {
      console.error("Error deleting pump and image:", error);
    }
  };

  const fetchUserPumps = async () => {
    try {
      const { data, error } = await supabase
        .from("pumps")
        .select()
        .eq("user_id", user.id)
        .order("created_at", { ascending: false });

      if (error) {
        setFetchError("Could not fetch user pumps");
        setUserPumps(null);
        console.error(error);
      }

      if (data) {
        setUserPumps(data);
        setFetchError(null);
      }
    } catch (error) {
      console.error("Error fetching user pumps:", error);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const {
        data: { user },
        error,
      } = await supabase.auth.getUser();

      if (error) {
        setFetchError("Error fetching user information");
        console.error(error);
      } else {
        setUser(user);
        setFetchError(null);
      }
    } catch (error) {
      console.error("Error fetching user information:", error);
    }
  };

  const fetchReferralCount = async () => {
    try {
      const { data, error } = await supabase
        .from("referrals")
        .select("reffered_by")
        .eq("reffered_by", user.id);
  
      if (error) {
        console.error("Error fetching referral count:", error);
        return;
      }
        // Assuming only one streak per user
        // const { data: streaks, error: streaksError } = await supabase
        // .from('streaks')
        // .select('*')
        // .eq("user_id", user.id);

        //   if (streaksError) {
        //       console.error("Error fetching streaks:", streaksError);
        //       return;
        //   }

        //   if (streaks.length > 0) {
        //       setUserStreak(streaks[0].streak);
        //   } else {
        //       // If no streak found, set streak to 0 or handle accordingly
        //       setUserStreak(0);
        //   }

                  // Fetch user position and streak from the 'streaks' table
        const { data: streakData, error: streakError } = await supabase
        .from('streaks')
        .select('user_id, streak')
        .order('streak', { ascending: false });

    if (streakError) {
        console.error('Error fetching streak data:', streakError);
        return;
    }

    // Find user's streak and position
    const userStreakData = streakData.find(streak => streak.user_id === user.id);
    const userPosition = streakData.findIndex(streak => streak.user_id === user.id) + 1;

    setUserStreak(userStreakData ? userStreakData.streak : 0);
    setUserPosition(userPosition);

         

      setReferralCount(data.length);
    } catch (error) {
      console.error("Error fetching referral count:", error);
    }
  };
  
  const fetchStreakEmoji = async () => {
    try {
        // Fetch the user's latest pump
        const { data: latestPump, error: pumpError } = await supabase
            .from('pumps')
            .select('created_at')
            .eq('user_id', user.id)
            .order('created_at', { ascending: false })
            .limit(1);

        if (pumpError) {
            console.error('Error fetching latest pump:', pumpError);
            return;
        }


        if (latestPump.length > 0) {
            // Calculate the time elapsed since the last post
            const lastPostDate = new Date(latestPump[0].created_at);
            const today = new Date();
            const daysSinceLastPost = Math.floor((today - lastPostDate) / (1000 * 60 * 60 * 24));


            // Determine the streak emoji based on the time elapsed
            const streakEmoji = daysSinceLastPost >= 2 ? '⏳' : '🔥';

            // Set the streak count and emoji
            setStreakEmoji(streakEmoji);

        } else {
            // If no pumps found, set default emoji
            setStreakEmoji('🔥');

            console.log('No pumps found, default streak');
        }
    } catch (error) {
        console.error('Error fetching streak info:', error);
    }
};


  useEffect(() => {
    fetchUserInfo();
  }, [userId]);
  
  useEffect(() => {
    if (user) {
      checkUsername().then((result) => {
        setLoading(false);
      });
      fetchUserPumps();
      fetchReferralCount();
      getGreetingMessage();
      fetchStreakEmoji();
    }
  }, [user]);

   // Group user pumps by month/year
   const groupPumpsByMonthYear = () => {
    if (!userPumps) return {};

    const groupedPumps = {};

    userPumps.forEach((pump) => {
      const createdAt = new Date(pump.created_at);
      const monthYear = `${createdAt.toLocaleString('default', { month: 'long' })} ${createdAt.getFullYear()}`;

      if (!groupedPumps[monthYear]) {
        groupedPumps[monthYear] = [];
      }

      groupedPumps[monthYear].push(pump);
    });

    return groupedPumps;
  };

  const groupedPumps = groupPumpsByMonthYear();
  

//   const handleCardClick = (pumpId) => {
//     // Toggle between 2 and 1 columns
//     setNumColumns(numColumns === 2 ? 1 : 2);

//     // Scroll to the clicked pump after a short delay
//     setTimeout(() => {
//       const fragment = document.getElementById(`fragment-${pumpId}`);
//       if (fragment) {
//         fragment.scrollIntoView({ behavior: 'auto', block: 'center' });
//       }
//     }, 50);
// };

const handleCardClick = () => {
  // Call the onClick function passed from the Profile component
  console.log("No fullscreen on profile rn")

};

  

  return (
    <div className="profile-wrapper">
      <div className="profile-container">
        {!loading && !username ? (
          <Username />
        ) : (
          usernameCheckSuccess ? (
            user && (
              <>
                <div className="top-profile-nav">
                  <h1 className="profile-h1 color-2">supapump</h1>
                  <h2 className="profile-h2 color-3">{greeting}, {username} 👋</h2>
                  <div className="profile-stats">

                  <div  className="userData-container">
                      <p className={`streak-count color-3 userData  ${userStreak >= 100 ? 'oneHundred' : ''}`}>
                          {streakEmoji} {userStreak}
                      </p>
                    </div>

                      <div  className="userData-container">
                        <p className="color-3 userData placement" >🏆 #{userPosition}</p>
                      </div>

                   
          
                      <div  className="userData-container-2">
                       <p className="views-count color-3 userData">👀 Views: {viewsCount}</p>
                      </div>
                    

                    <div  className="userData-container-2">
                      <p className="referral-count color-3 userData">⚡️ Refs: {referralCount}</p>
                    </div>
           

                 
                  

                  </div>
                </div>
           
                {userPumps === null ? (
                  <div className="loading-wrapper">
                    <p className="loading-message">loading...</p>
                  </div>
                  // <ProfileSkeletonLoader/>
                ) : userPumps.length === 0 ? (
                  <div className="tip-wrapper">
                   <div className="tip-container">
                      <p className="tip-message">Your in !</p>
                      <p className="tip-text">Explore around and get started with your first pump 🔥</p>
                   </div>
                  </div>
                ) : (
                  <div className="pumps-wrapper">

   
                    
                    <div className="top-nav-wrapper">
                 

                    </div>
  
                    {( groupedPumps && Object.keys(groupedPumps).map((monthYear) => (
                        <div key={monthYear}>
                          <h3 className="timeline color-3">{monthYear}</h3>
                          <div className="pumps-wrapper">
                            <Masonry
                              breakpointCols={numColumns}
                              className="masonry-grid"
                              columnClassName="masonry-grid-column"
                            >
                              {groupedPumps[monthYear].map((pump, index) => (
                              <React.Fragment key={pump.id}>
                              {/* <div id={`fragment-${pump.id}`} onClick={() => handleCardClick(pump.id)}> */}
                                <PumpCard
                                  key={pump.id}
                                  pump={pump}
                                  onDelete={() => handleDelete(pump.id, pump.file_path)}
                                  currentUser={user}
                                  onPress={handleVisibility}
                                  showVisibilityIndicator={true}
                                  onDoublePress={handleDoublePress}
                                  id={`fragment-${pump.id}`} 
                                  onClick={handleCardClick}
                                  // onClick={() => handleCardClick(pump.id)}
                                />
                              {/* </div> */}
                            </React.Fragment>
                            
                              ))}
                              {/* Conditional rendering of placeholder-item */}
                              {groupedPumps[monthYear].length < 2 && (
                                <div className="placeholder-item"></div>
                              )}
                            </Masonry>
                          </div>
                        </div>
                      ))
                    )} 
                  </div>
                )}
              </>
            )
          ) : null
        )}
      </div>
    </div>
  );
}

export default Profile;


