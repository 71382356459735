import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LockIcon from "../icons/lock.svg";
import "../styles/privacy.css";

const Privacy = () => {
  const navigate = useNavigate();
 

  return (
    <div className="privacy-container">
      <div class="container">
        <div class="modal-name">Supapump</div>
        <div class="modal-header">Privacy Policy</div>
        <div class="modal-date">Effective Date: 09/24/2024</div>

        <div class="modal-text">
            Supapump is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your information when you use our app.
        </div>

        <div class="modal-h2">1. Information We Collect</div>
        <div class="modal-text">
            Personal Information: We collect your email, password, and username when you create an account.
        </div>
        <div class="modal-text">
            User Content: We collect the images you post on your progression feed and explore page.
        </div>
        <div class="modal-text">
            Usage Data: We collect information about your interactions with the app, such as the pages you visit and the features you use.
        </div>

        <div class="modal-h2">2. How We Use Your Information</div>
        <div class="modal-text">
            To Provide and Improve Our Services: We use your information to operate, maintain, and enhance Supapump.
        </div>
        <div class="modal-text">
            To Communicate with You: We may use your email to send you updates, newsletters, and other communications.
        </div>
        <div class="modal-text">
            For Research and Development: We may use your information for data analysis, research, and product development.
        </div>

        <div class="modal-h2">3. Sharing Your Information</div>
        <div class="modal-text">
            With Service Providers: We may share your information with third-party service providers who help us operate and improve the app.
        </div>
        <div class="modal-text">
            For Legal Reasons: We may disclose your information if required by law or in response to a valid legal request.
        </div>
        <div class="modal-text">
            For Business Transfers: If Supapump is involved in a merger, acquisition, or sale of assets, your information may be transferred to the new owner.
        </div>

        <div class="modal-h2">4. Data Retention</div>
        <div class="modal-text">
            We retain your information for as long as your account is active or as needed to provide you with our services. If you request account deletion, we will delete your data within 30 days.
        </div>

        <div class="modal-h2">5. Security</div>
        <div class="modal-text">
            We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.
        </div>

        <div class="modal-h2">6. Your Rights</div>
        <div class="modal-text">
            Access and Update: You can access and update your account information at any time through the app.
        </div>
        <div class="modal-text">
            Deletion: You can either delete your account through help setting in your account or request to delete your account by contacting us at accounts@supapump.me. Account deletion can take up to 30 days to process.
        </div>

        <div class="modal-h2">7. Children's Privacy</div>
        <div class="modal-text">
            Supapump is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will delete it.
        </div>

        <div class="modal-h2">8. Changes to This Privacy Policy</div>
        <div class="modal-text">
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the app. Your continued use of the app after any changes become effective constitutes your acceptance of the new Privacy Policy.
        </div>

        <div class="modal-h2">9. Contact Us</div>
        <div class="modal-text">
            If you have any questions about this Privacy Policy, please contact us at privacy@supapump.me.
        </div>
    </div>
     
    </div>
  );
};

export default Privacy;
