// useCheckUsername.js
import { useEffect } from "react";
import supabase from "../config/supabaseClient";

const useCheckUsername = (session, setUsername) => {
  useEffect(() => {
    const checkUsername = async () => {
      try {
        if (!session) {
          // console.warn('Session is not available yet.');
          return;
        }
    
        const { data, error } = await supabase
          .from('username')
          .select('*')
          .eq('user_id', session.id);
    
        if (error) {
          console.error('Error checking username:', error);
          return;
        }
    
        const fetchedUsername = data.length > 0 ? data[0].username : null;

        setUsername(fetchedUsername);
      } catch (error) {
        console.error('Error checking username:', error);
      }
    };

    checkUsername();
  }, [session, setUsername]);
};

export default useCheckUsername;
