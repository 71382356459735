import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import useCheckUser from '../hooks/useCheckUser';
import '../styles/createusername.css';

import Gallery from 'react-image-gallery';
import TutImg1 from "../icons/tutimg1.png"
import TutImg2 from "../icons/tutimg2.png"
import TutImg3 from "../icons/tutimg3.png"

import UserIcon from "../icons/user2.svg"
import BackIcon from "../icons/arrow-left.svg"

const Username = () => {
  const [username, setUsername] = useState("");
  const [session, setSession] = useState(null);
  const [error, setError] = useState(null);
  const [tutorialStep, setTutorialStep] = useState(1);

  useCheckUser(setSession);

  const handleUsername = async () => {
    // e.preventDefault();

    console.log("Handle Username");

    try {
      if (!session || !session.id) {
        setError("User not authenticated");
        return;
      }

      // Check username length
      if (username.length < 3 || username.length > 14) {
        setError("Username must be between 3 and 14 characters");
        return;
      }

      // Check for spaces in the username
      if (/\s/.test(username)) {
        setError("Username cannot contain spaces");
        return;
      }

      // Check for specific reserved usernames
      const reservedUsernames = ["profile", "authlogin", "explore", "home"];
      if (reservedUsernames.includes(username.toLowerCase())) {
        setError("Username is reserved and cannot be used");
        return;
      }

      // Check for alphanumeric characters only
      if (!/^[a-zA-Z0-9]+$/.test(username)) {
        setError("Username can only contain letters and numbers");
        return;
      }

      const { data: streakData, error: streakError } = await supabase
      .from('streaks')
      .insert([{ user_id: session.id, streak: 0 }]);


      const { data, error } = await supabase
        .from('username')
        .insert([{ user_id: session.id, username: username }]);

      if (error) {
        if (error.code === '23505') {
          setError("Username is already taken. Please choose another one.");
        } else {
          setError("Error inserting username");
          console.error(error);
        }
      } else {
        setError(null);
        console.log('Username created!');
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error(error);
    }
  };

  const handleBack = () => {
    if (tutorialStep > 1) {
      setTutorialStep(tutorialStep - 1);
    }
  };

  const handleContinue = async () => {
    try {

      if (tutorialStep === 1) {
        // Check username length
        if (username.length < 3 || username.length > 14) {
          setError("Username must be between 3 and 14 characters");
          return;
        }
  
        // Check for spaces in the username
        if (/\s/.test(username)) {
          setError("Username cannot contain spaces");
          return;
        }
  
        // Check for specific reserved usernames
        const reservedUsernames = ["profile", "authlogin", "explore", "recover", "privacy"];
        if (reservedUsernames.includes(username.toLowerCase())) {
          setError("Username is reserved and cannot be used");
          return;
        }
  
        // Check for alphanumeric characters only
        if (!/^[a-zA-Z0-9]+$/.test(username)) {
          setError("Username can only contain letters and numbers");
          return;
        }
  
        // Check if the username is already taken
        const { data: existingUsernames, error: usernameError } = await supabase
          .from('username')
          .select('*')
          .eq('username', username);
  
        if (usernameError) {
          setError("An error occurred while checking username availability");
          console.error(usernameError);
          return;
        }
  
        if (existingUsernames.length > 0) {
          setError("Username is already taken. Please choose another one.");
          return;
        }
  
        // If all checks pass, move to the next step
        setTutorialStep(tutorialStep + 1);

      } else if (tutorialStep === 2) {
 
        console.log("2 : Current step:", tutorialStep);

        if (!session || !session.id) {
          setError("User not authenticated");
          return;
        }

        console.log("3 :  Current step:", tutorialStep);
        // Handle username submission
        if (!session || !session.id) {
          setError("User not authenticated");
          return;
        }
        await handleUsername();

        setTutorialStep(tutorialStep + 1); // Move to step 3

      } else if (tutorialStep == 3) {}


    } catch (error) {
      setError("An unexpected error occurred");
      console.error(error);
    }
  };
  

  const images = [
    {original: TutImg1},
    {original: TutImg2},
    {original: TutImg3}
  ];

  const galleryOptions = {
    showBullets: true,
    showThumbnails: false,
    showFullscreenButton: false,
    showPlayButton: false,
    LazyLoad: true,
    autoPlay: false,
  };
  

  return (
    <div className="tutorial-username-wrapper">

      {/* Tutorial Box 1 */}
      <div className="tutorial-box" style={{ display: tutorialStep == 1 ? 'block' : 'none' }}>

        <div className="create-username-wrapper">
          <h2 className="create-username-h1">Create your username</h2>  

          {/* <div className="username-message-container">
            <p className="username-message1">This will be your quicklink</p>

            <p className="username-message2">Ex. supapump.me/username</p>
          </div>  */}
        
          <form className="create-username-form" onSubmit={handleUsername}>

            <label className="auth-form-label">
              <img className="auth-form-icon" src={UserIcon} alt="" />
              <input
                className="create-username-input"
                placeholder="Enter a Username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />

          </label>
             
                {error && (<p className="username-error-message">{error}</p> )}
          </form>
        </div>
        
      </div>

      {/* Tutorial Box 2 */}
      <div className="tutorial-box" style={{display: tutorialStep == 2 ? 'block' : 'none' }}>

      <div className="create-username-wrapper">
          <h2 className="create-username-h1">Learn the basics</h2>  

          <p>1️⃣ Post your daily workout pump 🤳</p>

          <p>2️⃣ Explore and compete with others 🏆</p>

          <p>3️⃣ Show off your progression 😱</p>

          <p>4️⃣ Stay consistent, keep the streak alive 🔥</p>
          
          <h5 className="basics-h5">You ready for that ?</h5>
        </div>
      </div>

        {/* Tutorial Box 3 */}
        <div className="tutorial-box" style={{ display: tutorialStep == 3 ? 'block' : 'none' }}>

          <div className="create-username-wrapper">
            <h2 className="create-username-h1">Make me a real app 📲</h2>  

            <p>Use your browsers setting to add supapump as an app</p>

            <p>Follow the slides below for help 🥸</p>

            <div  className="app-img-container">
                      <Gallery  items={images} {...galleryOptions}/>
            </div>

          </div>
         
        </div>

  

        <div className="arrow">
         
          {tutorialStep < 3 && (
            <div className="arrow-button" onClick={handleContinue}>
              {tutorialStep == 1 ? "Continue" : "Finish" || tutorialStep == 2 ? "Oh I'm ready" : "finish"}
          
            </div>
          )}
           {/* {tutorialStep > 1 && tutorialStep < 3 && (
            <div className="arrow-button2" onClick={handleBack}>
                 <img className="back-arrow" src={BackIcon} alt="<" />
            </div>
          )} */}
        </div>
    </div>
  );
};

export default Username;




