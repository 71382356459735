
import React, { useState, useEffect } from "react";
import "../../styles/home/hero.css"
import heroImg from "../../icons/home/heroImg.png"
import EmailIcon from "../../icons/mail.svg";
import LockIcon from "../../icons/lock.svg";


const Hero = ({
  email,
  password,
  setEmail,
  setPassword,
  handleAuth,
  HandleForgotPassword,
  error,
  isCooldown
}) => {

  return (
   
    <section className="hero">
    <img src={heroImg} alt="Gym Background" />
    <div className="overlay">
      <h1 className="h">The <span className="bold">progress</span> starts,</h1>  
      <h1>when you do.</h1>
      {/* <button className="hero-btn" onClick={onClick}> Get the app</button> */}
      <form onSubmit={(e) => e.preventDefault()} className="auth-form">
        <label className="auth-form-label">
            <img className="auth-form-icon" src={EmailIcon} alt="Email" />
            <input
                className="auth-form-input"
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
            />
        </label>
        <label className="auth-form-label">
            <img className="auth-form-icon" src={LockIcon} alt="Password" />
            <input
                className="auth-form-input"
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
            />
                <div 
                  className={`forgot-btn ${isCooldown ? 'disabled' : ''}`} 
                  onClick={HandleForgotPassword}
                >
                  {isCooldown ? 'Sent' : 'Forgot?'}
                </div>
        </label>

        <div className="ButtonContainer">
            <button
                className="buttonSignup"
                type="button"
                onClick={() => handleAuth(false)}
            >
                Signup
            </button>
            <button
                type="button"
                className="buttonLogin"
                onClick={() => handleAuth(true)}
            >
                Login
            </button>
            
        </div>

      
      </form>

    </div>
  </section>
  
  );
};

export default Hero;
