import React, { useState, useEffect, lazy, Suspense } from "react";
import { useParams, useNavigate,  } from "react-router-dom";
import supabase from "../config/supabaseClient";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import useCheckUser from "../hooks/useCheckUser";
import useCheckUsername from "../hooks/useCheckUsername";

import ExploreIcon from "../icons/explore.svg"
import shareIcon from "../icons/link.svg";
import logoutIcon from "../icons/log-out.svg";
import CloseIcon from "../icons/x.svg"
import UserIcon from "../icons/user.svg"
import SettingsIcon from "../icons/settings.svg"
import AddIcon from "../icons/camera.svg";
import Create from "../components/Create";
import LockIcon from "../icons/lock2.svg"
import RefIcon from "../icons/user-plus.svg";
import GalIcon from "../icons/grid2.svg"


import "../styles/navbar.css";
import '../index.css';

const Navbar = ({profileId}) => {
  const navigate = useNavigate();
  const [session, setSession] = useState(null);
  const [username, setUsername] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allUserNames, setAllUserNames] = useState([]);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const location = useLocation();

  const SettingsModal = lazy(() => import("../components/Settings"));
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const [user, setUser] = useState(null);

  useEffect(() => {
    // Add or remove the hide-overflow class based on the value of showSettingsModal
    if (showSettingsModal) {
        document.body.classList.add('hide-overflow');
        setShowLinks(false);
    } else {
        document.body.classList.remove('hide-overflow');
    }

    // Clean up effect
    return () => {
        document.body.classList.remove('hide-overflow');
    };
}, [showSettingsModal]);

  const fetchUserInfo = async () => {
    
      const {
        data: { user },
        error,
      } = await supabase.auth.getUser();

      if (error) {
        console.error(error);
      } else {
        setUser(user);
      }
    
    
  };

  const [showLinks, setShowLinks] = useState(false);

  useCheckUser(setSession);
  useCheckUsername(session, setUsername);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add('hide-overflow');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove('hide-overflow');
  };

  useEffect(() => {
    // Close both modals when the page changes
    setIsModalOpen(false);
    setShowSettingsModal(false);
    setShowLinks(false);
  }, [location.pathname]);

  useEffect(() => {
    // Add or remove the hide-overflow class based on the value of showSettingsModal
    document.body.classList.remove('hide-overflow');
}, []);

  const showLockToast = () => {
    toast("You have already posted today!", {
      position: "bottom-center",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // const handleLogout = async () => {
  //   const confirmLogout = window.confirm("Are you sure you want to logout?");
  //   if (confirmLogout) {
  //     try {
  //       const { error } = await supabase.auth.signOut();
  //       if (error) {
  //         console.error("Logout error:", error.message);
  //       } else {
  //         console.log("User logged out successfully.");
  //         setSession(null);
  //         window.location.href = "/";
  //       }
  //     } catch (error) {
  //       console.error("Logout error:", error.message);
  //     }
  //   }
  // };

  const fetchAllUsernames = async () => {
    try {
      const { data, error } = await supabase.from("username").select("username");

      if (error) {
        console.error("Error fetching usernames:", error);
        return;
      }

      setAllUserNames(data);
    } catch (error) {
      console.error("Error fetching usernames:", error);
    }
  };

  const setCreateLock = async () => {
    try {
      // console.log(session.id);
  
      // Get today's date
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to midnight

      console.log(today)
  
      const { data: existingPostData, error: existingPostError } = await supabase
        .from('pumps')
        .select()
        .eq('user_id', session.id)
        .gte('created_at', today.toISOString()); // Use the ISO string of today's date
  
      // console.log("post data", existingPostData);
      // console.log("post data length", existingPostData.length);
  
      if (existingPostData && existingPostData.length > 0) {
        setDisableCreateButton(true); // Disable create button if user has already posted today
      }
    } catch (error) {
      console.error("Error checking user's post:", error);
    }
  };

  useEffect(() => {
    fetchAllUsernames();
    fetchUserInfo()
    if (session) {
      setCreateLock(); // Call setCreateLock if session exists
    }
  }, [session]);



  const openLinks = () => {
    setShowLinks(!showLinks);
    setShowSettingsModal(false);
  };


  const handleShareOption1 = (url) => {
    setShowLinks(false);
    toast("Copied Gallery Link", {
      position: "bottom-center",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    const textArea = document.createElement("textarea");
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  const handleShareOption2 = (url) => {
    setShowLinks(false);
    toast("Copied Refferal Link", {
      position: "bottom-center",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    const textArea = document.createElement("textarea");
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowLinks(false); // Close the options container when scrolled
    };


    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {isModalOpen && <Create onClose={closeModal} />}

      {showLinks && (
        <div className="links-container">

          <div className="link-container linkr"  onClick={() => handleShareOption1(`https://supapump.me/${username}`)}>
            <img className="link" src={GalIcon} alt="Gallery" />
            <p>Gallery</p>
          </div>

          <div className="link-container" onClick={() => handleShareOption2(`https://supapump.me/authlogin?ref=${user.id}`)}>
           <img className="link" src={RefIcon} alt="Reffer" />
           <p>Refer</p>
          </div>
          
        </div>
      )}

      <div className={`settings-slide ${showSettingsModal ? "settings-slide-active" : ""}`}>
          <Suspense fallback={<div></div>}>
              {/* Render SettingsModal only when showSettingsModal is true */}
              {showSettingsModal && <SettingsModal />}
          </Suspense>
      </div>


      <nav className="navbar-wrapper">
  
        <div className="navbar-container">

        {session !== null  && (  
            <div className="auth-nav-wrapper color-1-bkg ">

              <Link to="/explore" className='navButton'>
                <img src={ExploreIcon} className={`button-icon ${location.pathname === "/explore" ? "active-nav" : ""}`} alt="Explore" />
                <p className="navText">Explore</p>
              </Link>

              <div onClick={openLinks} className='navButton'>
                <img className={`button-icon ${showLinks ? "active-nav" : ""}`} src={shareIcon} alt="Share" />
                <p className="navText">Share</p>
              </div>

              <div
                // onClick={openModal}
                className={`navButton ${disableCreateButton ? 'disabled' : ''}`}
              >
                {disableCreateButton ? (
                    <img className="button-icon" src={LockIcon} alt="Locked"  onClick={showLockToast} />
                    
                ) : (
               <div>
                   <img className="button-icon" src={AddIcon} alt="Create" onClick={openModal}/>
               </div>
                
                )}
                      <p className="navText">Create</p>
              </div>

              <Link to="/profile" className='navButton'>
                <img src={UserIcon} className={`button-icon ${location.pathname === "/profile" ? "active-nav" : ""}`} alt="Profile" />
                <p className="navText">Profile</p>
              </Link>

              <div
                className='navButton'
                onClick={() => setShowSettingsModal(prevState => !prevState)}
              >
                {showSettingsModal ? (
                  <img src={CloseIcon} className={`button-icon-x ${showSettingsModal ? "active-nav" : ""}`} alt="Close" />
                ) : (
                  <img src={SettingsIcon} className="button-icon" alt="Settings" />
                )}
                <p className="navText">Settings</p>
              </div>

            </div>
        )}

          {session === null && !["/profile", "/explore"].includes(location.pathname) && (
            <div className="auth-nav-wrapper-2">
              <Link
                className="button2 CTA2 stretch color-2-bkg"
                to={`https://supapump.me/authlogin?ref=${profileId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="CTA-text">Join Supapump</p>
              </Link>
              {allUserNames.length > 0 && <p className="q-text">👆 {allUserNames.length} others claimed their link 👆</p>}
            </div>
          )}
        
      </div>
    </nav>
    </div>
  );
};

export default Navbar;
