import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import supabase from "../config/supabaseClient";
import LockIcon from "../icons/lock.svg";
import { toast } from "react-toastify";
import "../styles/recover.css";

const Recover = () => {
  const navigate = useNavigate();
  const { token } = useParams(); // Assuming your reset password link contains a token parameter
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  

  const handleChangePassword = async () => {
    try {
      if (password !== confirmPassword) {
        throw new Error("Passwords do not match");
      }

      const { data, error } = await supabase.auth.updateUser({
        password: password
      })

      if (error) {
        throw error;
      }

      toast.success("Password updated successfully!");
      navigate("/profile"); // Redirect to login page after password change
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="auth-wrapper auth-color-1">
      <h1 className="auth-h1 color-2 auth-color-2">supapump</h1>
      <h3 className="auth-h2 auth-color-3">Recover your <span className="auth-h2-italic">password,</span></h3>
      <h3 className="auth-h3 auth-color-3 recover-h2">and dont forget 😤</h3>

      <form onSubmit={(e) => e.preventDefault()} className="auth-form">
        <label className="auth-form-label">
          <img className="auth-form-icon" src={LockIcon} alt="Lock" />
          <input
            className="auth-form-input"
            type="text"
            value={password}
            placeholder="New password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>

        <label className="auth-form-label">
          <img className="auth-form-icon" src={LockIcon} alt="Lock" />
          <input
            className="auth-form-input"
            type="password"
            value={confirmPassword}
            placeholder="Confirm password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </label>

        <div className="auth-form-button-group">
          <button
            type="button"
            className="button-signup-2 color-2-bkg"
            onClick={handleChangePassword}
          >
            Change Password
          </button>
        </div>
      </form>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Recover;
