import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import PumpCard from "../components/PumpCard";
import Masonry from 'react-masonry-css';
import GridSVG from '../icons/grid.svg'
import SquareSVG from '../icons/square.svg'
import '../styles/gallery.css'
import Navbar from "../components/Navbar";


import ExploreIcon from "../icons/explore.svg"
import StreakBlank from "../icons/streakBlank.svg"


const UserGallery = () => {
  const navigate = useNavigate();
  const { username } = useParams();
  const [pumps, setPumps] = useState([]);
  const [user, setUser] = useState(null);
  const [userStreak, setUserStreak] = useState('')
  const [userPosition, setUserPosition] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [numColumns, setNumColumns] = useState(2);

  const [profileId, setProfileId] = useState(null);

  const handleDoublePress = () => {
    console.log('Liked Post')
  }

  useEffect(() => {
    const fetchData = async () => {
      try {


        const { data: usernameData, error: usernameError } = await supabase
          .from('username')
          .select('*')
          .eq('username', username);

        if (usernameError) {
          console.error('Error checking user_id:', usernameError);
          return;
        }

        const fetchedUserId = usernameData.length > 0 ? usernameData[0].user_id : null;

    
        setProfileId(fetchedUserId)
        console.log(profileId)

          // Assuming only one streak per user
          const { data: streakData, error: streakError } = await supabase
          .from('streaks')
          .select('user_id, streak')
          .order('streak', { ascending: false });

        if (streakError) {
          console.error('Error fetching streak data:', streakError);
          return;
        }

        const userStreakData = streakData.find(streak => streak.user_id === fetchedUserId);
        const userPosition = streakData.findIndex(streak => streak.user_id === fetchedUserId) + 1;

        setUserStreak(userStreakData ? userStreakData.streak : 0);
        setUserPosition(userPosition);

        const { data: pumpsData, error: pumpsError } = await supabase
          .from('pumps')
          .select()
          .eq('user_id', fetchedUserId)
          .eq('visible', true)
          .order('created_at', { ascending: false });

        if (pumpsError) {
          console.error('Error fetching user pumps:', pumpsError);
        } else {
          setPumps(pumpsData);
        }

        setFetchError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    }

    fetchData();
  }, [username]);

  


  useEffect(() => {
    const fetchView = async () => {
      // ... (your existing code)

      try {
        // Check if profileId is not null before making the fetch request
        if (profileId !== null) {
          // Fetch the current 'viewed' count
          const { data: viewsData, error: viewsError } = await supabase
            .from('views')
            .select('viewed')
            .eq('id', profileId);

          if (viewsError) {
            console.error('Error fetching current views:', viewsError);
            return;
          }

          if (viewsData.length > 0) {
            // If the row exists, increment the 'viewed' count by 1 and update the row
            await supabase.from('views').upsert([
              {
                id: profileId,
                viewed: viewsData[0].viewed + 1,
              },
            ]);
          } else {
            // If the row doesn't exist, insert a new row with 'viewed' set to 1
            await supabase.from('views').upsert([
              {
                id: profileId,
                viewed: 1,
              },
            ]);
          }
        }

        // ... (your existing code)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchView();
  }, [username, profileId]);

    // Group user pumps by month/year
    const groupPumpsByMonthYear = () => {
      if (!pumps) return {};
  
      const groupedPumps = {};
  
      pumps.forEach((pump) => {
        const createdAt = new Date(pump.created_at);
        const monthYear = `${createdAt.toLocaleString('default', { month: 'long' })} ${createdAt.getFullYear()}`;
  
        if (!groupedPumps[monthYear]) {
          groupedPumps[monthYear] = [];
        }
  
        groupedPumps[monthYear].push(pump);
      });
  
      return groupedPumps;
    };
  
    const groupedPumps = groupPumpsByMonthYear();

    const handlePumpCardClick = (pumpId) => {
      // Toggle between 2 and 1 columns
      setNumColumns(numColumns === 2 ? 1 : 2);
  
      // Scroll to the clicked pump after a short delay
      setTimeout(() => {
        const fragment = document.getElementById(`fragment-${pumpId}`);
        if (fragment) {
          fragment.scrollIntoView({ behavior: 'auto', block: 'center' });
        }
      }, 50);
  };

  const handleCardClick = () => {
    // Call the onClick function passed from the Profile component
    console.log("Card Clicked")
  };

  return (
    <div className="profile-wrapper">
       <Navbar profileId={profileId} />
      <div className="user-name-2">
        {/* <img className='user-icon-gal' src={userIcon} alt="" /> */}
        <h1 className="user-gallery-h1">{username}</h1>

        <div className="stats-container">
          <div className="gallery-container">
              <p className={`user-gallery-streak ${userStreak >= 100 ? 'oneHundred' : ''}`}>🔥 {userStreak}</p>
          </div>

          <div className={`gallery-container ${userPosition === 1 ? 'firstPlaceContainer' : ''}`}>
            <p className={`user-gallery-streak ${userPosition === 1 ? 'firstPlace' : ''}`}>
              🏆 #{userPosition}
            </p>
          </div>
        </div>
       
      </div>
      <div className="pumps-gal">
         <div className="top-nav-wrapper">
                      <div className="view-toggle-wrapper">

                      </div>
                      
                      </div>
                {(groupedPumps && Object.keys(groupedPumps).map((monthYear) => (
                    <div key={monthYear}>
                      <h3 className="timeline">{monthYear}</h3>
                      <div className="pumps-wrapper">
                        <Masonry
                          breakpointCols={numColumns}
                          className="masonry-grid"
                          columnClassName="masonry-grid-column"
                        >
                          {groupedPumps[monthYear].map((pump) => (
                            <React.Fragment key={pump.id}>
                              <div id={`fragment-${pump.id}`} onClick={() => handlePumpCardClick(pump.id)}>
                                <PumpCard
                                  key={pump.id}
                                  pump={pump}
                                  onDoublePress={handleDoublePress}
                                  onClick={handleCardClick}
                                />
                              </div>
                            </React.Fragment>
                          ))}
                          {/* Conditional rendering of placeholder-item */}
                          {groupedPumps[monthYear].length < 2 && (
                            <div className="placeholder-item"></div>
                          )}
                        </Masonry>
                      </div>
                    </div>
                  ))
                )}
      </div>
    </div>
  );
};

export default UserGallery;




