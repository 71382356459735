import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";

import PumpCard from "../components/PumpCard";
import Masonry from "react-masonry-css";
import Likes from '../components/Likes';

import "../styles/explore.css";

import UserIcon from "../icons/user.svg";
import ShowIcon from "../icons/eye.svg";
import NoShowIcon from "../icons/eye-off.svg";
import DirIcon from "../icons/arrow-left.svg";
import StreakBlank from "../icons/streakBlank.svg"
import GridSVG from '../icons/grid.svg'
import SquareSVG from '../icons/square.svg'

const Explore = () => {
  const navigate = useNavigate();
  const [allUserPumps, setAllUserPumps] = useState(null);
  const [showOnExplore, setShowOnExplore] = useState(null);
  const [usernames, setUsernames] = useState({});
  const [isTextVisible, setIsTextVisible] = useState(false);


  const [selectedPumpCardId, setSelectedPumpCardId] = useState(null); // State for selected pump card ID
  const [isLikeModalOpen, setIsLikeModalOpen] = useState(false);

  const [streakUsernames, setStreakUsernames] = useState({});

  const [topStreaks, setTopStreaks] = useState([]);

  const [numColumns, setNumColumns] = useState(2);


  const toggleLikeModal = (pumpCardId) => {
    setSelectedPumpCardId(pumpCardId);
    setIsLikeModalOpen(!isLikeModalOpen);
  };


  const getTopStreaks = async () => {
    try {
      // First, get the user IDs where 'show_on_explore' is true
      const { data: users, error: userError } = await supabase
        .from('username')
        .select('user_id, username')
        .eq('show_on_explore', true);
  
      if (userError) {
        console.error('Error fetching usernames:', userError);
        return;
      }
  
      // Create a mapping of user_id to username
      const usernameMap = {};
      const userIds = users.map(user => {
        usernameMap[user.user_id] = user.username;
        return user.user_id;
      });
  
      // Now, get the top streaks for the filtered users
      const { data: streaks, error } = await supabase
        .from('streaks')
        .select('*')
        .in('user_id', userIds) // Only get streaks for the filtered user IDs
        .order('streak', { ascending: false })
        .limit(10);
  
      if (error) {
        throw error;
      }
  
      setTopStreaks(streaks);
      setStreakUsernames(usernameMap);
  
    } catch (error) {
      console.error('Error retrieving top streaks:', error.message);
    }
  };
  
 
  useEffect(() => {
    fetchShowOnExplore();
    getTopStreaks();
  }, []);

  const fetchShowOnExplore = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }

      const { data: userData, error: userError } = await supabase
        .from("username")
        .select("show_on_explore")
        .eq("user_id", user.id)
        .single();

      if (userError) {
        console.error("Error fetching user data:", userError);
        return;
      }

      setShowOnExplore(userData.show_on_explore);
      fetchAllUserPumps();
    } catch (error) {
      console.error("Error fetching show_on_explore:", error);
    }
  };

  const fetchAllUserPumps = async () => {
    try {
       // Get current UTC time
       const utcNow = new Date();
    
       // Convert UTC time to Pacific Time
       const pstNow = new Date(utcNow.toLocaleString("en-US", {timeZone: "America/Vancouver"}));
       
       // Calculate the start of the day (midnight) in Pacific Time
       const pstStartOfDay = new Date(pstNow.getFullYear(), pstNow.getMonth(), pstNow.getDate());
       
       // Calculate three days ago in Pacific Time
       const threeDaysAgo = new Date(pstStartOfDay);
       threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

      //  const threeDaysAgo = new Date();
      //  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
      //  threeDaysAgo.setHours(0, 0, 0, 0);
 

      const { data: users, error: userError } = await supabase
        .from("username")
        .select("user_id, username")
        .eq("show_on_explore", true);

      if (userError) {
        console.error("Error fetching usernames:", userError);
        return;
      }

      const usernameMap = {};
      users.forEach((user) => {
        usernameMap[user.user_id] = user.username;
      });

      setUsernames(usernameMap);

      
      const userIds = users.map((user) => user.user_id);

      const { data, error } = await supabase
        .from("pumps")
        .select()
        .in("user_id", userIds)
        .eq('visible', true)
        .gte("created_at", threeDaysAgo.toISOString())
        .order("created_at", { ascending: false });

      if (error) {
        console.error("Error fetching pumps:", error);
        return;
      }

      setAllUserPumps(data);

    } catch (error) {
      console.error("Error fetching pumps:", error);
    }
  };

  const getFormattedDate = (date) => {
    const options = { weekday: "long", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleProfileDirClick = async (pumpId) => {
    try {
      const { data: pumpData, error: pumpError } = await supabase
        .from("pumps")
        .select("user_id")
        .eq("id", pumpId)
        .single();

      if (pumpError) {
        console.error("Error fetching pump data:", pumpError);
        return;
      }

      const userId = pumpData.user_id;

      const { data: userData, error: userError } = await supabase
        .from("username")
        .select("username")
        .eq("user_id", userId)
        .single();

      if (userError) {
        console.error("Error fetching user data:", userError);
        return;
      }

      const username = userData.username;

      navigate(`/${username}`);
    } catch (error) {
      console.error("Error handling double press:", error);
    }
  };

  const toggleShowOnExplore = async (value) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }
  
      const userId = user.id;
  
      const { data, error } = await supabase
        .from('username')
        .update({ show_on_explore: value })
        .eq('user_id', userId)
        .select();
  
      if (error) {
        console.error("Error toggling show on explore:", error);
        return;
      }
  
      setShowOnExplore(value);
  
      // Make the text visible
      setIsTextVisible(true);
  
      // Hide the text after 3 seconds
      setTimeout(() => {
        setIsTextVisible(false);
      }, 2000);
  
      // window.location.reload();
        // Fetch updated pumps list
    fetchAllUserPumps();
    } catch (error) {
      console.error("Error toggling show on explore:", error);
    }
  };
  

  const handleDoublePress = async () => {
    console.log("Double Press")
  };

  function navigateToProfile(username) {
    window.location.href = `/${username}`;
}

const handlePumpPress = (pumpId) => {
  // Toggle between 2 and 1 columns
  setNumColumns(numColumns === 2 ? 1 : 2);

  // Scroll to the clicked pump after a short delay
  setTimeout(() => {
    const pumpElement = document.getElementById(pumpId);
    if (pumpElement) {
      pumpElement.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }, 50);
};

const handleCardClick = () => {
  // Call the onClick function passed from the Profile component
  console.log("Card Clicked")
};


const handleLike = (pumpId, emojiType) => {
  console.log('Pump ID:', pumpId);
  console.log('Emoji Type:', emojiType);
  // You can perform further actions here, such as updating the database or UI
};


  return (
    <div className="explore-page">
      <div>
        <h1 className="color-2 explore-h1 ">supapump</h1>
        <div className="explore-nav-2">
          <h1 className="explore-h22">Explore 🦾</h1>
          <div className="show-container">
            <p className={`show-text ${!isTextVisible ? 'show-text-hidden' : ''}`}>
              {showOnExplore ? "Shown on Explore" : "Not Shown on Explore"}
            </p>
            <div className={"visible-button " + (showOnExplore ? "" : "")} onClick={() => toggleShowOnExplore(!showOnExplore)}>
              <img src={showOnExplore ? ShowIcon : NoShowIcon} className="button-icon" alt="Profile" />
            </div>
          </div>
          
        </div>
      </div>

      <div className="leaderboard-2">
        {topStreaks.map((streak, index) => {
          // Determine the color and background based on the index
          const color = index === 0 ? 'gold' : (index === 1 ? 'silver' : (index === 2 ? '#cd7f32' : '#545764'));
          const background = index === 0 ? 'gold-bg' : (index === 1 ? 'silver-bg' : (index === 2 ? 'bronze-bg' : 'grey-bg'));

          // Check if streak exceeds 100
          const extraStyle = streak.streak >= 100 ? 'oneHundred' : '';

          return (
            <div className={`streak ${index === 0 ? 'gold' : (index === 1 ? 'silver' : (index === 2 ? 'bronze' : ''))}`} key={index}
              onClick={() => navigateToProfile(streakUsernames[streak.user_id])}>
              <p className="leaderboard-place-2" style={{ color: color }}>#{index + 1}</p>
              <div className={`streak-wrapper ${background}`}>
                <p className={`leaderboard-streak-2 ${extraStyle}`}>🔥</p>
                <p className={`leaderboard-streak-3`}>{streak.streak}</p>
              </div>
              <div className="leaderboard-name-wrapper-2">
                <a className="leaderboard-name-2">{streakUsernames[streak.user_id]}</a>
              </div>
            </div>
          );
        })}
      </div>




      {allUserPumps === null ? (
        <p className="loading">Loading...</p>
      ) : allUserPumps.length === 0 ? (
        <div>
          <div className="explore-tutorial-container">
            <p className="explore-h2">Not enough posts yet.</p>
          </div>
        </div>
      ) : (
        <div className="pumps-wrapper">
        {[0, 1, 2].map((index) => (
          <div key={index}>
            <h3 className="timeline">{getFormattedDate(new Date(new Date().getTime() - index * 24 * 60 * 60 * 1000))}</h3>
            <Masonry  breakpointCols={numColumns} className="masonry-grid" columnClassName="masonry-grid-column">

              {allUserPumps.filter(pump => {
                const pumpDate = new Date(pump.created_at);
                return pumpDate.toDateString() === new Date(new Date().getTime() - index * 24 * 60 * 60 * 1000).toDateString();
              }).length === 0 ? (
                <div className="no-post-container">
                   <p className="no-post-text">No posts yet today</p>
                   {/* <p className="no-post-text">Get to it</p> */}
                </div>
               
              ) : (
                allUserPumps.filter(pump => {
                  const pumpDate = new Date(pump.created_at);
                  return pumpDate.toDateString() === new Date(new Date().getTime() - index * 24 * 60 * 60 * 1000).toDateString();
                }).map((pump) => (
                  <div>
                    <div key={pump.id} 
                    id={pump.id} onClick={() => handlePumpPress(pump.id)} 
                    >
                      <PumpCard pump={pump} onDoublePress={() => handleDoublePress()} onClick={handleCardClick}/>
                    </div>

                    <div className="explore-pumpcard-btns-container">

                        <p className="explore-username" onClick={() => handleProfileDirClick(pump.id)}>  {usernames[pump.user_id]}</p>
                      
                        {/* {selectedPumpCardId === pump.id && (
                          <div className="like-toggle-open">
                            <li  onClick={() => toggleLikeModal(pump.id)}>{isLikeModalOpen ? '❌' : '✅'}</li>
                            {isLikeModalOpen && (
                              <Likes
                                onClose={() => toggleLikeModal(null)}
                                onLike={(emojiType) => handleLike(selectedPumpCardId, emojiType)} 
                              />
                            )}
                          </div>
                        )}

                        {selectedPumpCardId !== pump.id && (
                          <div className="like-toggle-closed">
                            <li onClick={() => toggleLikeModal(pump.id)}>✅</li>
                          </div>
                        )} */}

                    </div>
                  
                     
                  </div>
                
                ))
              )}

              {allUserPumps.filter(pump => {
                const pumpDate = new Date(pump.created_at);
                return pumpDate.toDateString() === new Date(new Date().getTime() - index * 24 * 60 * 60 * 1000).toDateString();
              }).length < 2 && (
                <div className="placeholder-item"></div>
              )}
            </Masonry>
          </div>
        ))}
      </div>
      
      )}
    </div>
  );
};

export default Explore;