import { useState, useEffect } from "react";
import supabase from "../config/supabaseClient";

const useCheckViews = (profileId) => {
  const [viewsCount, setViewsCount] = useState(0);

  useEffect(() => {
    const fetchViewsCount = async () => {
      try {
        // Fetch the current 'viewed' count
        const { data: viewsData, error: viewsError } = await supabase
          .from('views')
          .select('viewed')
          .eq('id', profileId);

        if (viewsError) {
          // console.error('Error fetching current views:', viewsError);
          return;
        }

        const currentViewedCount = viewsData.length > 0 ? viewsData[0].viewed : 0;

        // Set the views count in the state
        setViewsCount(currentViewedCount);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchViewsCount();
  }, [profileId]);

  const incrementViews = async () => {
    try {
      // Increment the 'viewed' count by 1 and update the row
      await supabase.from('views').upsert([
        {
          id: profileId,
          viewed: viewsCount + 1,
        },
      ]);

      // Update the views count in the state
      setViewsCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error('Error updating views count:', error);
    }
  };

  return { viewsCount, incrementViews };
};

export default useCheckViews;
