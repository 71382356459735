import React, { useState, useEffect } from "react";
import "../../styles/home/navbar.css";

import spIcon from "../../icons/home/spIcon.svg";
import menuIcon from "../../icons/home/menu.svg"; // Add a hamburger icon
import closeIcon from "../../icons/home/x.svg"

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    function useScrollDirection() {
        const [scrollDirection, setScrollDirection] = useState(null);

        useEffect(() => {
            let lastScrollY = window.pageYOffset;

            const updateScrollDirection = () => {
                const scrollY = window.pageYOffset;
                const direction = scrollY > lastScrollY ? "down" : "up";
                if (
                    direction !== scrollDirection &&
                    (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
                ) {
                    setScrollDirection(direction);
                }
                lastScrollY = scrollY > 0 ? scrollY : 0;
            };
            window.addEventListener("scroll", updateScrollDirection); // add event listener
            return () => {
                window.removeEventListener("scroll", updateScrollDirection); // clean up
            };
        }, [scrollDirection]);

        return scrollDirection;
    }

    const scrollDirection = useScrollDirection();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className={`header ${scrollDirection === "down" ? "hide" : "show"}`}>
            <div className="space">
                <div className="logo">
                    <img src={spIcon} alt="Gym Background" />
                </div>

                {/* Hamburger button for mobile view */}
                <div className="hamburger-menu" onClick={toggleMenu}>
                  <img src={menuOpen ? closeIcon : menuIcon} alt="Menu" />
                </div>

                {/* Menu sections that collapse into the hamburger menu */}
                <div className={`menu ${menuOpen ? "open" : "closed"}`}>
                    <div className={`menu-mid ${menuOpen ? "open-mid" : "closed-mid"}`}>
                        <a href="#">What's New!</a>
                        <a href="#">Follow Us</a>
                        <a href="#">About</a>
                    </div>

                    <div className={`menu-right ${menuOpen ? "open-right" : "closed-right"}`}>
                        <a href="#">Sign up</a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
