import { useEffect } from "react";
import supabase from "../config/supabaseClient";

const useCheckUser = (setSession, setUserDataChecked) => {
  useEffect(() => {
    const checkUser = async () => {
      try {
        const { data: userData, error } = await supabase.auth.getUser();
        if (userData) {
          setSession(userData.user);
          // console.log('User Checked: ' + userData.user);
        } else {
          setSession(null);
        }
      } catch (error) {
        console.error("Error checking user:", error.message);
      } finally {
        if (setUserDataChecked) {
          setUserDataChecked(true);
        }
      }
    };
    checkUser();
  }, [setSession, setUserDataChecked]);
};

export default useCheckUser;

