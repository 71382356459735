import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import supabase from '../config/supabaseClient';
import Compressor from 'compressorjs';
import xIcon from '../icons/x.svg'
import StreakModal from "./Streaks"
import '../styles/create.css'

import Gallery from 'react-image-gallery';

import { ring } from 'ldrs'

const Create = ({ onClose })  => {

  ring.register()

  const [files, setFiles] = useState([]);
  const [formError, setFormError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [streak, setStreak] = useState([])

  // const handleFileChange = async (e) => {
  //   const selectedFiles = Array.from(e.target.files);

  //   try {
  //     const compressedFiles = await Promise.all(
  //       selectedFiles.map(async (file) => {
  //         return new Promise((resolve) => {
  //           new Compressor(file, {
  //             quality: 0.25,
  //             success(result) {
  //               resolve(result);
  //             },
  //             error(error) {
  //               console.error('Error compressing file:', error.message);
  //               resolve(file);
  //             },
  //           });
  //         });
  //       })
  //     );

  //     console.log('Compressed files:', compressedFiles); 
  //     setFiles(compressedFiles);
  //   } catch (error) {
  //     console.error('Error compressing files:', error.message);
  //   }
  // };

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const filteredFiles = [];
  
    try {
      await Promise.all(
        selectedFiles.map(async (file) => {
          // Check if the file type is an image
          if (file.type.startsWith('image/') || /\.(jpg|jpeg|png|gif|img)$/i.test(file.name)) {
            const compressedFile = await new Promise((resolve) => {
              new Compressor(file, {
                quality: 0.25,
                success(result) {
                  resolve(result);
                },
                error(error) {
                  console.error('Error compressing file:', error.message);
                  resolve(file);
                },
              });
            });
            filteredFiles.push(compressedFile);
            setFormError(null);
          } else {
            console.log(`File ${file.name} is not an image and will be skipped.`);
            setFormError('You can only post images');
          }
        })
      );
  
      console.log('Filtered files:', filteredFiles);
      setFiles(filteredFiles);
    } catch (error) {
      console.error('Error compressing files:', error.message);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted'); 
  
    if (!files.length || files.length > 3) {
      console.log('Invalid number of files selected'); 
      setFormError('Choose 1-3 photos to upload!');
      return;
    }
  
    // Step 1: Get user information
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    const userId = user?.id;
    console.log('User ID:', userId);
  
    if (!userId || userError) {
      console.error('Error getting user information:', userError);
      setFormError('Please sign in to post.');
      return;
    }
  
    // Step 2: Check for existing post
    const { data: existingPostData, error: existingPostError } = await supabase
      .from('pumps')
      .select()
      .eq('user_id', userId)
      .gte('created_at', new Date().toISOString().split('T')[0]);
  
    console.log('Existing post data:', existingPostData);
  
    if (existingPostData && existingPostData.length > 0) {
      setFormError('You have already made a post today. You can only post once a day.');
      return;
    }
  
    let filePaths = ''; // Declare filePaths here
  
    try {
      setLoading(true);
      console.log('Loading state set to true');
  
      const currentDate = new Date(Date.now() - 8 * 60 * 60 * 1000).toISOString().split('T')[0];
      const uploadFolder = `${userId}/${currentDate}`;
      console.log('Upload folder:', uploadFolder);
  
      // Step 3: Upload files
      const uploadPromises = files.map((file, index) => {
        return supabase.storage
          .from('pumpgallery')
          .upload(`${uploadFolder}/${file.name}`, file);
      });
  
      const fileDataArray = await Promise.all(uploadPromises);
      console.log('File data array:', fileDataArray);
  
      filePaths = fileDataArray.map((fileData) => fileData.data?.path).filter(Boolean).join(',');
      console.log('File paths:', filePaths);
  
      if (!filePaths) {
        throw new Error('File paths are empty.');
      }
  
      // Step 4: Log data to be inserted into "pumps" table
      const pumpDataToInsert = {
        user_id: userId,
        file_path: filePaths,
        photo_count: files.length,
      };
      console.log('Data to be inserted into pumps table:', pumpDataToInsert);
  
      // Step 5: Insert into the "pumps" table
      const { data: pumpData, error: pumpError } = await supabase
        .from('pumps')
        .insert([pumpDataToInsert]);
  
      if (pumpError) {
        console.error('Supabase error inserting pump data:', pumpError);
        throw new Error('Error inserting pump data');
      }
  
      console.log('Pump data inserted:', pumpData);
  
      // Step 6: Update streak
      const newStreak = streak + 1;
      const { data: streakData, error: streakError } = await supabase
        .from('streaks')
        .update({ streak: newStreak })
        .eq('user_id', userId);
  
      if (streakError) {
        console.error('Supabase error updating streak:', streakError);
        throw new Error('Error updating streak');
      }
  
      console.log('Streak updated:', streakData);
  
      setFormError(null);
      setLoading(false);
      console.log('Refreshing page...');
      window.location.reload();
      console.log('Page refreshed');
  
    } catch (error) {
      console.error('Error:', error.message);
  
      // Rollback: delete uploaded files if an error occurs
      try {
        if (filePaths) {
          const fileNames = filePaths.split(',');
          const deletePromises = fileNames.map((filePath) => 
            supabase.storage.from('pumpgallery').remove([filePath])
          );
          await Promise.all(deletePromises);
          console.log('Files rolled back (deleted) due to error');
        }
      } catch (deleteError) {
        console.error('Error during rollback (file deletion):', deleteError.message);
      }
  
      setLoading(false);
      setFormError('Please retry, or use a better connection');
    }
  };
  

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log('Form submitted'); 

  //   if (!files.length || files.length > 3) {
  //     console.log('Invalid number of files selected'); 
  //     setFormError('Choose 1-3 photos to upload!');
  //     return;
  //   }

  //   const { data: { user }, error: userError } = await supabase.auth.getUser();
  //   const userId = user?.id;

  //   if (!userId || userError) {
  //     console.error('Error getting user information:', userError);
  //     setFormError('Please sign in to post.');
  //     return;
  //   }
    
  //   const { data: existingPostData, error: existingPostError } = await supabase
  //     .from('pumps')
  //     .select()
  //     .eq('user_id', userId)
  //     .gte('created_at', new Date().toISOString().split('T')[0]);

        
  //   console.log('Existing post data:', existingPostData);

  //   if (existingPostData && existingPostData.length > 0) {
  //     setFormError('You have already made a post today. You can only post once a day.');
  //     return;
  //   }

  //   try {
  //     setLoading(true);
  //     console.log('Loading state set to true');

  //     const currentDate = new Date(Date.now() - 8 * 60 * 60 * 1000).toISOString().split('T')[0];
  //     const uploadFolder = `${userId}/${currentDate}`;



      
  //     console.log('Upload folder:', uploadFolder);

  //     const uploadPromises = files.map((file, index) => {
  //       return supabase.storage
  //         .from('pumpgallery')
  //         .upload(`${uploadFolder}/${file.name}`, file);
  //     });

  //     console.log("uploadpromisses" + uploadPromises)


  //     const fileDataArray = await Promise.all(uploadPromises);
  //     console.log('fileDataArray:', fileDataArray);

  //     const filePaths = fileDataArray.map((fileData) => fileData.data.path).join(',');

  //       console.log('filePaths:', filePaths);

  //       if (!filePaths) {
  //         throw new Error('File paths are empty.');
  //       }


  //     const { data: pumpData, error } = await supabase
  //       .from('pumps')
  //       .insert([
  //         {
  //           user_id: userId,
  //           file_path: filePaths,
  //           photo_count: files.length,
  //         },
  //       ]);

  //       console.log("pump data:" + pumpData);
      

  //     if (error) {
  //       console.error('Error inserting pump data:', error);
  //       setFormError('An error occurred while saving the pump data.');
  //       return;
  //     }

  //     const newStreak = streak + 1;

  //     // Update streak count in the 'streaks' table
  //     const { data: streakData, error: streakError } = await supabase
  //     .from('streaks')
  //     .update({ streak: newStreak })
  //     .eq('user_id', userId);

  //     if (streakError) {
  //       console.error('Error updating streak:', streakError);
  //       setFormError('An error occurred while updating the streak.');
  //       return;
  //     }

  //     setFormError(null);
  //     console.log('Refreshing page...');

  //       setLoading(false);

  //     window.location.reload();
  //     console.log('Page refreshed');


  //   } catch (error) {
  //     console.error('Error:', error.message);
  //     setLoading(false);
  //     setFormError('An unexpected error occurred.');
  //   } 
  // };

  

   
  

  useEffect(() => {
      const handleStreakCheck = async () => {

        const { data: { user }, error: userError } = await supabase.auth.getUser();
        const userId = user?.id;

        const { data: existingStreakData, error: existingStreakError } = await supabase
        .from('streaks')
        .select('streak')
        .eq('user_id', userId);

        setStreak(existingStreakData[0].streak)
      }
   
      handleStreakCheck()
  }, []);


  const galleryOptions = {
    showBullets: true,
    showThumbnails: false,
    showFullscreenButton: false,
    showPlayButton: false,
    LazyLoad: true,
  }

  return (
      <div className="create-container">
            
              <div className="image-previews">
                  <Gallery items={files.map((file, index) => ({
                      original: URL.createObjectURL(file),
                      thumbnail: URL.createObjectURL(file),
                      originalAlt: `Image ${index + 1}`,
                      thumbnailAlt: `Thumbnail ${index + 1}`
                  }))} {...galleryOptions}  />
              </div>

              <form className='create-form' onSubmit={handleSubmit}>
              {formError && <p className="error">{formError}</p>}

              
                      <div className='buttons-wrapper'>
                            <p className='create-text'>( Choose up to 3 images )</p>
                            <label htmlFor="file" className="custom-file-label">Select Images</label>
                            <input className="create-files visually-hidden" type="file" id="file" multiple onChange={handleFileChange} />
                          <button className='create-pump-button' type="submit">Create Pump</button>
                      </div>
                      

                  <div className='close-wrapper'>
                        <Link className='x-button' onClick={onClose}>
                          <img className='x-icon'  src={xIcon} alt="Profile" />
                      </Link>
                  </div>
              </form>

              {loading && <div className="loading-overlay">
                <div className='loading-container'>
                  <l-ring
                    size="25"
                    stroke="2"
                    bg-opacity="0"
                    speed="2" 
                    color="#83f199" 
                  ></l-ring>
                  <p>Creating post...</p>
                </div>
              </div>}

              
      </div>
  );
};

export default Create;
