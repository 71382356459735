import React, { useState, useEffect } from 'react';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../styles/pumpcard.css'

import { ring } from 'ldrs'

const PumpCard = ({ pump, onDelete, currentUser, onPress, showVisibilityIndicator, onDoublePress, onClick,   }) => {
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [pressCount, setPressCount] = useState(0);

  const [loadedImagesCount, setLoadedImagesCount] = useState(1);
  const [loading, setLoading] = useState(true);
  
  ring.register()

  const handleImageLoad = () => {
    // setTimeout(() => {
    //   setLoading(false);
    // }, 400000); 
    setLoading(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsOptionsMenuOpen(false); // Close the options menu when scrolled
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleCardClick = () => {
    // Call the onClick function passed from the Profile component
    console.log("Pump id: From Card: " + pump.id)
    onClick(pump.id);
  };

  const toggleOptionsMenu = () => {
    setIsOptionsMenuOpen(!isOptionsMenuOpen);
  };

  const closeOptionsMenu = () => {
    setIsOptionsMenuOpen(false);
  };

  const handleOptionsMenuClick = (option) => {
    closeOptionsMenu();
  };

  const handleVisibility = () => {
    onPress(pump.id);
  };

  const handleDelete = async () => {
    onDelete();
  };

  const dateObject =
    pump.created_at instanceof Date ? pump.created_at : new Date(pump.created_at);

  const images = pump.file_path.split(',').map((path, index) => ({
    original: `https://ubakkumncqyqowxhclff.supabase.co/storage/v1/object/public/pumpgallery/${path.trim()}`,
    thumbnail: `https://ubakkumncqyqowxhclff.supabase.co/storage/v1/object/public/pumpgallery/${path.trim()}`,
  }));


  const galleryOptions = {
    showBullets: false,
    showThumbnails: false,
    showFullscreenButton: false,
    useBrowserFullscreen: false,
    showPlayButton: false,
    LazyLoad: true,
    onBeforeSlide: (currentIndex) => setLoadedImagesCount(currentIndex + 1),
    onSlide: (index) => setCurrentSlideIndex(index),
    renderLeftNav: (onClick, disabled) => (
      <button
        type="button"
        className={`image-gallery-left-nav ${disabled ? 'disabled' : ''}`}
        onClick={onClick}
        aria-label="Previous Slide"
      >
        <i className="material-icons" style={{ fontSize: 0, color: 'white' }}>chevron_left</i>
      </button>
    ),
    renderRightNav: (onClick, disabled) => (
      <button
        type="button"
        className={`image-gallery-right-nav ${disabled ? 'disabled' : ''}`}
        onClick={onClick}
        aria-label="Next Slide"
      >
        <i className="material-icons" style={{ fontSize: 0, color: 'white' }}>chevron_right</i>
      </button>
    ),
  };

  return (
    <div className='pump-card' >
    
    {loading && (
      <p className={`loading-message ${loading ? 'visible' : ''}`}>.</p>
    )}


      <div style={{
        opacity: loading ? '0' : '1',
        transition: 'opacity 0.5s ease' // Adjust the duration and timing function as needed
      }}>
          {images.length > 1 && (
            <p className='image-count'>{`${loadedImagesCount}/${images.length}`}</p>
          )}
        
        <Gallery onClick={handleCardClick} items={images} {...galleryOptions} onImageLoad={handleImageLoad} />

          {currentUser && pump.user_id === currentUser.id && (
            <>
            <div
              className={`options-menu-trigger ${isOptionsMenuOpen ? 'open' : ''}`}
              onClick={toggleOptionsMenu}
            >
              <p className='dots'>...</p>
            </div>
            <div
              className={`options-menu ${isOptionsMenuOpen ? 'open' : ''}`}
              // onClick={toggleOptionsMenu}
            >
              <div className='options'>
                <div className="option1" onClick={handleDelete}>
                  💣
                </div>
                {showVisibilityIndicator && (
                  <div className='option visibility' onClick={handleVisibility}>
                    {pump.visible ? '🙉' : '🙈'}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>    
    </div>
  );
};

export default PumpCard;
