import React, { useState } from "react";
import {
  BrowserRouter, Routes, Route, 
  Navigate, useLocation,
} from "react-router-dom";

import HomePage from "./pages/HomePage";
import UserGallery from "./pages/UserGallery";
import Profile from "./pages/Profile";
import AuthLogin from "./pages/AuthLogin";
import Explore from "./pages/Explore";
import Streak from "./pages/Streak"
import Recover from "./pages/Recover"
import Privacy from "./pages/Privacy"

import "./fonts/ClashDisplay-Bold.ttf"
import "./fonts/ClashDisplay-Semibold.ttf"


import Navbar from "./components/Navbar";
import useCheckUser from "./hooks/useCheckUser";

const Navigation = () => {
  const location = useLocation();
  const [session, setSession] = useState(null);
  const [userDataChecked, setUserDataChecked] = useState(false);

  useCheckUser(setSession, setUserDataChecked);

  if (!userDataChecked) {
    return null;
  }

  if (location.pathname == "/auth/v1/verify") {
      return null;

  }

  if (!session && !["/authlogin"].includes(location.pathname)) {
    // Check if the current route is not the user gallery route
    if (!/^\/[a-zA-Z0-9]+$/.test(location.pathname)) {
      window.location.href = "/authlogin";
      return null;
    }
  }
  
  if (!session && ["/profile"].includes(location.pathname)) {
    window.location.href = "/authlogin";
  }

  // if (session && !["/profile"].includes(location.pathname)) {
  //   return null;
  // }

  if (session && (location.pathname === "/profile" || location.pathname === "/explore"))  {
    return <Navbar />;
  }

};

function App() {
  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route path="/" element={<Navigate to="/" />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/:username" element={<UserGallery />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/recover" element={<Recover />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/authlogin/:ref?" element={<AuthLogin />} />
        {/* <Route path="/explore" element={<Explore />} /> */}
        <Route
          path="/explore"
          element={<PrivateRoute component={Explore} />}
        />
          <Route
          path="/streak"
          element={<PrivateRoute component={Streak} />}
        />   
      </Routes>
    </BrowserRouter>
  );
}

// Custom PrivateRoute component to check for authentication
const PrivateRoute = ({ component: Component }) => {
  const [session, setSession] = useState(null);
  const [userDataChecked, setUserDataChecked] = useState(false);

  useCheckUser(setSession, setUserDataChecked);

  if (!userDataChecked) {
    return null;
  }

  if (!session) {
    // If user is not authenticated, redirect to login page
    return <Navigate to="/authlogin" />;

  }

  // If user is authenticated, render the specified component
  return <Component />;
};

export default App;
