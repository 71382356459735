import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/home/home.css";
import Navbar from "../components/home/Navbar";
import Hero from "../components/home/Hero"
import IconArt1 from "../components/home/IconArt1";
import supabase from "../config/supabaseClient";

const HomePage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isCooldown, setIsCooldown] = useState(false);

  const handleAuth = async (isLogin) => {
    try {
      // Validate email and password (you can expand on this if needed)
      if (!validateEmail(email)) {
        setError("Please enter a valid email address.");
        return;
      }
      if (password.length < 6) {
        setError("Please enter a password with 6 or more characters.");
        return;
      }

      if (isLogin) {
        // Perform login
        const { data, error } = await supabase.auth.signInWithPassword({ email, password });
        if (data?.user) {
          console.log("Login success");
          navigate("/profile");
        } else if (error) {
          console.error("Login error:", error.message);
          setError(error.message);
        }
      } else {
        // Perform sign up
        const { data, error } = await supabase.auth.signUp({ email, password });
        if (data?.user) {
          console.log("Signed up");
          navigate("/profile");
        } else if (error) {
          setError("Sign-up failed. Please try again.");
        }
      }
    } catch (error) {
      console.error("Authentication error:", error.message);
      setError(error.message);
    }
  };

  const HandleForgotPassword = async () => {
    if (isCooldown) return;
    if (!email) {
      setError("Please enter your email to recover password.");
      return;
    }
    setIsCooldown(true);
    setError("");

    let { data, error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) {
      console.error("Error resetting password:", error.message);
    } else {
      console.log("Recovery email sent to:", email);
    }
    setTimeout(() => setIsCooldown(false), 300000);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div className="homePage">


        <Navbar />

        <Hero
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          handleAuth={handleAuth}
          HandleForgotPassword={HandleForgotPassword}
          error={error}
          isCooldown={isCooldown}
        />

        {/* <IconArt1 /> */}

    </div>
  );
};

export default HomePage;
