import React, { useState, useEffect } from 'react';
import supabase from '../config/supabaseClient';

const StreakBar = () => {
  const [user, setUser] = useState(null);
  const [userStreak, setUserStreak] = useState(null);
  const [fetchError, setFetchError] = useState(null);

  const fetchUserInfo = async () => {
    try {
      const { data: { user }, error } = await supabase.auth.getUser();

      if (error) {
        setFetchError("Error fetching user information");
        console.error(error);
      } else {
        setUser(user);
        setFetchError(null);
      }
    } catch (error) {
      console.error("Error fetching user information:", error);
    }
  };

  const fetchStreakCount = async () => {
    try {
      if (!user) return;

      const { data: streaks, error: streaksError } = await supabase
        .from('streaks')
        .select('streak')
        .eq("user_id", user.id);

      if (streaksError) {
        console.error("Error fetching streaks:", streaksError);
        return;
      }

      if (streaks.length > 0) {
        setUserStreak(streaks[0].streak);
      } else {
        setUserStreak(0);
      }
    } catch (error) {
      console.error("Error fetching streak count:", error);
    }
  };


  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (user) {
      fetchStreakCount();
    }
  }, [user]);

  const percentage = userStreak ? (userStreak / 100) * 100 : 0;

  return (
      <div className='streak-container'>

          <div className="streak-bar">
            <div className='streak-bar-progress' style={{ width: `${percentage}%` }}></div>
          </div>
        
      </div>
  );
};

export default StreakBar;